
.all__habilities{
    width: 100%;
    padding: 30px 0px;
    background-color: #EBEBEB;
}

// DIV TO MAKE H1 CENTER
.contenedor__principal{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

// DIV DEL H1 HABILIDADES
.habilities-name{
    width: 270px;
    padding: 12px;
    background-color: #212529;
    color: white;
    border-radius: 20px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.habilities-name h1{
    font-family: 'poppins', sans-serif;
}

.habilities{
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 150px;
    flex-wrap: wrap;
}

// FRONT-END
.front-end{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}
// DIV TO MAKE H1 FRONT-END BEFORE
.front-end__name{
    width: 270px;
    padding: 12px;
    background-color: #212529;
    color: white;
    border-radius: 20px;
    position: relative;
    text-align: center;
    font-family: 'poppins', sans-serif;
}

// CONTAINER
.contenedor__habilities-front{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.habilities__front-end{
    // width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
// H5
.habilities__front-end h5{
    font-family: 'poppins', sans-serif;
    font-size: 17px;
}

// BACK-END
.back-end{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
// DIV TO MAKE H1 BACK-END BEFORE
.back-end__name{
    width: 270px;
    padding: 12px;
    background-color: #212529;
    color: white;
    border-radius: 20px;
    position: relative;
    text-align: center;
    font-family: 'poppins', sans-serif;
}

.contenedor__habilities-back{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.habilities__back-end{
    // width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.habilities__back-end h5{
    font-family: 'poppins', sans-serif;
}

// Icons
._habilities-images{
    width: 80px;
    height: 80px;
}



// RESOPNSIVE
@media screen and (max-width:599px) {
    .all__habilities{
        width: 100%;
        padding: 75px 0px;
    }
    
    .habilities{
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 20px;
    };
    .contenedor__habilities-front{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .contenedor__habilities-back{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .contenedor__principal{
        margin-bottom: 30px;
    }
}

