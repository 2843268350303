
// Narvar

.main-container{
    width: 100%;
    height: 730px;
    background: url('../../assets/jeorge-minimalist-desk-setup-02-5.jpeg');
    background-size: cover;
    margin-bottom: 70px;
    max-width: 100%;
    backdrop-filter: blur(3px);
}

.header{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 150px;
    padding: 20px 120px;
}

// RESPONSIVE
.header__responsive{
    display: none;
}
.menu{
    display: none;
}

.links{
    font-size: 35px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: color 300ms;
    font-family: 'Poppins', sans-serif;

    &:hover{
        color: #DC143C;
    }
};

.links-hijos{
    font-size: 25px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    transition: all 300ms;

    &:hover{
        color: #DC143C;
    }
};
.navbar-link{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0px;
}
// Fin del navbar

// Second Container
.second__container{
    text-align: center;
    width: 100%;
    height: auto;
    font-family: 'poppins';
    margin-bottom: 20px;
}
.second__container h4{
    font-size: 25px;
    color: #DC143C;
    letter-spacing: 2px;
};
.second__container h1{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 70px;
    letter-spacing: 4px;
    margin-bottom: 12px;
}

// list de abilidades
.list-ability{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px white;
    margin-bottom: 20px;
}
.span-list{
    border-left: 1px solid gray;
    padding: 0px 30px;

    &:first-child{
        border: none;
    }
};

.boton-about{
    font-size: 14px;
    letter-spacing: 3px;
    border: 4px solid gray;
    padding: 15px;
    border-radius: 100px;
    transition: border 300ms;
    background-color: transparent;

    &:hover{
        border: 4px solid #DC143C;
    }
}
.boton-about a{
    color: white;
    text-decoration: none;
    padding: 20px;
}

// ICONS Social
._redes-icons{
    font-size: 40px;
    cursor: pointer;
    color: #fff;
}

// Twitter Icon
._redes-icons.twitter:hover{
    color: #1A8CD8;
}
// Linkedin icon
._redes-icons.linkedin:hover{
    color: #0A66C2;
    background-color: white;
}


@media screen and (max-width: 599px){
    .main-container{
        max-width: 100%;
        height: 750px;
        margin-bottom: 0px;
    }
    .header{
        display: none;
    };
    .links{
        font-size: 15px;
    }
    .links-hijos{
        font-size: 13px;
    }
    .second__container{
        max-width: 100%;
        padding: 200px 20px 0px 20px;
    }
    .second__container h1{
        font-size: 30px;
        margin-bottom: 40px;
    }
    .second__container h4{
        font-size: 17px;
    }
    
    .list-ability{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 100px;
    }
    // RESPONSIVE DEL NAVBAR
    .menu{
        display: block;
        background: black;
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        padding: 0px 20px;
        position: fixed;
        margin: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
    }
    .menu button{
        background-color: black;
        border: none;
        outline: none;
    }
    .menu i {
        font-size: 27px;
        color: #DC143C;
    }
    .menu h1{
        color: white;
        font-family: 'poppins', sans-serif;
    }

    .navbar-link{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        max-width: 100%;
        background: black;
        position: absolute;
        top: 66px;
        left: -100%;
        opacity: 1;
        transition: all 500ms;
    }

    .navbar-link.active{
        left: 0%;
        transition: all 500ms;
    }

    .navbar-links{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0px;
        color: #fff;
    }
    .navbar-links-no{
        position: absolute;
        left: -100px;
        transition: all 1s ease;
        opacity: 0;
    }

    .navbar-links a{
        font-size: 17px;
    }

};
