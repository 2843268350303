.projects__main{
    width: 100%;
    padding: 120px 40px;
    padding-top: 40px;
}

.projets{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.projects-info{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'poppins', sans-serif;
}

.projects-info h4{
    font-size: 14px;
    font-family: 'poppins', sans-serif;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #DC143C;
}
.projects-info h1{
    margin-bottom: 15px;
}

// CARDS PROJECTS
.projects-data{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.projects-project{
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

._projects-project-img{
    width: 550px;
    height: 260px;
    max-width: 100%;
}

._card-efecto-hover-projects{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.623);
    position: absolute;
    bottom: -100%;
    transition: all 300ms;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
};

.projects-project:hover ._card-efecto-hover-projects{
    bottom: 0%;
    transition: all 300ms;
}

.h1-efecto-hover{
    color: white;
    font-family: 'poppins', sans-serif;
}

// Icons external link, github Link
.icons-externals-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-size: 30px;
}

@media screen and (max-width: 900px){
    .projects-data{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .projects__main{
        padding: 45px 20px;
        padding-top: 85px;
    }
}







