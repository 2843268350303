.all__about-me{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 40px 20px;
}
.about__me-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
}

.about__me-info h4{
    color: #DC143C;
    font-size: 16px;
    letter-spacing: 5px;
    font-family: 'poppins', sans-serif;
}

.about__me-info h1{
    font-family: 'poppins', sans-serif;
    font-size: 35px;
}

.info__ilustration{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
}

.info__ilustration img {
    width: 200px;
}
.info__ilustration p{
    width: 100%;
    font-family: 'poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    color: rgb(102, 100, 100);
}

// INTERES Y AFICIONES

.interes__aficciones{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.interes__aficciones h1{
    font-family: 'poppins', sans-serif;
    font-size: 35px;
}

.interes__ilustration{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 80%;
}

.interes__ilustration img {
    width: 200px;
}

.interes__ilustration p{
    width: 100%;
    font-family: 'poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    color: rgb(102, 100, 100);
}



@media screen and (max-width: 799px){
    .all__about-me{
        padding: 0px;
        padding: 40px 20px;
        padding-top: 90px;
    }
    .about__me-info p{
        width: 90%;
    }

    .about__me-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .info__ilustration{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        gap: 10px;
    }

    .interes__aficciones{
        width: 100%;
        margin-bottom: 0px;
    }
    .interes__aficciones h1{
        margin-bottom: 15px;
        width: 90%;
    }

    .interes__ilustration{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        gap: 10px;
    }

    .info__ilustration p{
        text-align: center;
        margin: 0px;
    }

    .interes__ilustration p {
        text-align: center;
        margin: 0px;
    }
}

